<template>
  <div class="auth">
    <div class="container">
      <div class="row align-items-center g-4 justify-content-between">
        <div class="col-lg-6 col-12">
          <img src="images/auth/login.svg" alt="singup" />
        </div>
        <div
          class="col-lg-5 mb-4 col-12 d-flex flex-column justify-content-start"
        >
          <h1 class="title">login</h1>
          <p>
            No tienes una cuenta ?<a
              href="register.html"
              class="unique-text"
            >
              Registrese ahora</a
            >
          </p>
          <input type="email" placeholder="Email" class="box col-12" />
          <input type="password" placeholder="password" class="box col-12" />
          <p>
            Olvidaste tu contraseña ? <a href="#" class="unique-text">Resetear contraseña</a>
          </p>
          <button class="btn mt-1">login</button>
        </div>
      </div>
    </div>
  </div>
</template>