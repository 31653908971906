<template>
    <!-- ======= srart Breadcrumbs ======= -->
  <section class="breadcrumbs py-4" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <ol>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Publicaciones</li>
      </ol>
      <h2 class="title">Listado de Publicaciones</h2>
      <p class="col-lg-10 col-12">
        Listado de publicaciones del blog.
      </p>
    </div>
  </section>
</template>