<template>
  <!-- ======= srart Breadcrumbs ======= -->
  <section class="breadcrumbs py-4" data-aos="fade-up" data-aos-delay="100">
    <div class="container">
      <ol>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Publicaciones</li>
      </ol>
      <h2 class="title">Mantenimiento de Publicaciones</h2>
      <p class="col-lg-10 col-12">Mantenimiento de publicaciones del blog.</p>
    </div>
  </section>
  <!--Blog Maintenance-->
  <section class="container py-5" id="post">
    <div class="row align-items-center justify-content-between">
      <div class="d-flex flex-column justify-content-start">
        <form>
          <div class="form-group mt-2 p-2">
            <input
              type="text"
              placeholder="Título"
              class="form-control"
              v-model="state.post.title"
            />
            <small id="titleHelp" class="form-text text-success"
              >Introduzca el título de la publicación.</small
            >
          </div>
          <div class="form-group mt-2 p-2 h-100">
            <ClassicEditor v-model="state.post.content" />
            <small id="contentHelp" class="form-text text-success text-sm"
              >Introduzca el contenido de la publicación.</small
            >
          </div>
          <div class="form-group mt-2 p-2">
            <input
              type="text"
              placeholder="Autor"
              class="form-control"
              v-model="state.post.author"
            />
            <small id="authorHelp" class="form-text text-success"
              >Introduzca el autor de la publicación.</small
            >
          </div>
          <div class="form-group mt-2 p-2">
            <input
              type="text"
              placeholder="Tags"
              class="form-control"
              v-model="state.post.tags"
            />
            <small id="tagsHelp" class="form-text text-success"
              >Introduzca algunos tags de la publicación separado por
              ",".</small
            >
          </div>
          <div class="form-group mt-2 p-2">
            <input
              type="text"
              placeholder="Categorías"
              class="form-control"
              v-model="state.post.categories"
            />
            <small id="categoriesHelp" class="form-text text-success"
              >Introduzca las categorías de la publicación separada por
              ",".</small
            >
          </div>
          <div class="form-group mt-2 p-2">
            <textarea
              type="text"
              placeholder="Referencias"
              class="form-control"
              v-model="state.post.references"
            ></textarea>
            <small id="referencesHelp" class="form-text text-success"
              >Introduzca las referencias utilizadas para publicar el artículo,
              separados por ",".</small
            >
          </div>
          <div class="form-group mt-2 p-2">
            <textarea
              type="text"
              placeholder="Artículos Relacionados"
              class="form-control"
              v-model="state.post.relatedPost"
            ></textarea>
            <small id="relatepostsHelp" class="form-text text-success"
              >Introduzca los artículos relacionados a esta publicación,
              separados por ",".</small
            >
          </div>
          <div class="mt-2">
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              @click="Save"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useRoute } from "vue-router";
import { GetErrorMessage } from "@/helpers/helper";
import { ApiBlogService} from "@/services/apiBlogService";

const route = useRoute();

const displayMessage = ref({ IsError: false, Message: "" });

const processing = ref(false);

const state = reactive({  
  post: {
    postId: 0,
    title: "",
    content: "",
    introduction: "",
    tags: [],
    categories: [],
    author: "anonimous",
    postDate: new Date(),
    references: [],
    relatedPost: [],
  },
});

const rules = {
  post: {
    title: { required },
    content: { required },
    author: { required },
    categories: { required },
  },
};

const v$ = useVuelidate(rules, state);

const Load = async () => {
  const id = route.params.id;
  await clean();
  await Get(id);
};

const clean = async () => {
  state.post.postId = 0;
  state.post.title = "";
  state.post.content = "";
  state.post.introduction = "";
  state.post.tags = [];
  state.post.categories = [];
  state.post.author = "anonimous";
  state.post.postDate = new Date();
  state.post.references = [];
  state.post.relatedPost = [];
};

const Get = async (id) => {
  processing.value = true;
  ApiBlogService.GetPost(id)
    .then((response) => {
      const result = response.data;
      if (result != null || result != undefined) {        
        state.post = result;
        processing.value = false;
      }
    })
    .catch(function (error) {
      const errorMessage = GetErrorMessage(error);
      displayMessage.value = { IsError: true, Message: errorMessage };
      processing.value = false;
    });
};

const Save = async () => {
  displayMessage.value = { IsError: false, Message: "" };
  try {
    v$.value.post.$touch();
    if (!v$.value.post.$invalid) {
      
      ApiBlogService.AddPost(state.post)
      .then((response) => {
        console.log(response);
        processing.value = false;
        displayMessage.value = {
          IsError: false,
          Message: `Información almacenada correctamente`,
        };
        this.clean();
      })
      .catch(function (error) {
        const errorMessage = GetErrorMessage(error);
        displayMessage.value = { IsError: true, Message: errorMessage };

        processing.value = false;
      });

      
      v$.value.post.$reset();
    }
  } catch (error) {
    const errorMessage = GetErrorMessage(error);
    displayMessage.value = { IsError: true, Message: errorMessage };
  }
};

onMounted(async () => {
  await Load();
});
</script>