import axios from "axios";

const apiMainBaseUrl = process.env.VUE_APP_MAIN_BASEURL;

// Main configuration.
const httpClientMain = axios.create({
    baseURL: apiMainBaseUrl,
    headers: {
        "Content-Type": "application/json",
    }
});

const ApiService = {
    GetHttpMainClient: function () {
        return httpClientMain;
    },
    GetApiMainBaseUrl: function() {
        return apiMainBaseUrl;
    }
};

export default ApiService;
export { ApiService };