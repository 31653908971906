import ApiService from "./apiService";

// Functions and Methods.
const ApiBlogService = {
    // Posts
    GetPosts: async function () {
        const httpClientMain = ApiService.GetHttpMainClient();
        return httpClientMain.get("api/Blog/GetAll");
    },
    GetPost: async function (id) {
        const httpClientMain = ApiService.GetHttpMainClient();
        return httpClientMain.get("api/Blog/Get",
            {
                params: {
                    id: id
                }
            });
    },
    AddPost: async function (data) {
        const httpClientMain = ApiService.GetHttpMainClient();
        return httpClientMain.post("api/Blog/Add", data);
    },
    UpdatePost: async function (data) {
        const httpClientMain = ApiService.GetHttpMainClient();
        return httpClientMain.put("api/Blog/Update", data);
    },
    DeletePost: async function (id) {
        const httpClientMain = ApiService.GetHttpMainClient();
        return httpClientMain.delete("api/Blog/Delete",
            {
                params: {
                    id: id
                }
            });
    },
    // Comments
    GetComments: async function () {
        const httpClientMain = ApiService.GetHttpMainClient();
        return httpClientMain.get("api/Comment/GetAll");
    },
    GetComment: async function (id) {
        const httpClientMain = ApiService.GetHttpMainClient();
        return httpClientMain.get("api/Comment/Get",
            {
                params: {
                    id: id
                }
            });
    },
    AddComment: async function (data) {
        const httpClientMain = ApiService.GetHttpMainClient();
        return httpClientMain.post("api/Comment/Add", data);
    },
    UpdateComment: async function (data) {
        const httpClientMain = ApiService.GetHttpMainClient();
        return httpClientMain.put("api/Comment/Update", data);
    },
    DeleteComment: async function (id) {
        const httpClientMain = ApiService.GetHttpMainClient();
        return httpClientMain.delete("api/Comment/Delete",
            {
                params: {
                    id: id
                }
            });
    },
}

export default ApiBlogService;
export { ApiBlogService };