import { createRouter, createWebHistory } from "vue-router";

// Layouts
import NoMenu from "../layouts/NoMenuMain.vue";
import SideMenu from "../layouts/SideMenu.Main.vue";

// Views
import AboutMain from "@/views/AboutMain.vue";
import BlogMain from "@/views/BlogMain.vue";
import BlogSingleMain from "@/views/BlogSingleMain.vue";
import ContactsMain from "@/views/ContactsMain.vue";
import HomeMain from "@/views/HomeMain.vue";
import PortfolioMain from "@/views/PortfolioMain.vue";
import PortfolioSingleMain from "@/views/PortfolioSingleMain.vue";
import ServicesMain from "@/views/ServicesMain.vue";
import ServicesSingleMain from "@/views/ServicesSingleMain.vue";
// Views / Solutions
import ComponentsMain from "@/views/ComponentsMain.vue";
import TemplatesMain from "@/views/TemplatesMain.vue";

// Views / No Menu
import PageNotFoundMain from "@/views/PageNotFoundMain.vue";
import PrivacyPolicyMain from "@/views/PrivacyPolicyMain.vue";
import TermOfUseMain from "@/views/TermOfUseMain.vue";

// Admin / Post
import PostMaintenance from "@/admin/blog/PostMaintenance.vue";
import PostList from "@/admin/blog/PostList.vue";
// Admin / Login
import LoginForm from "@/admin/login/LoginForm.vue";

const routes = [
    {
        path: "/no-menu",
        component: NoMenu,
        children: [
            {
                path: "/no-found",
                name: "no-menu-no-found",
                component: PageNotFoundMain,
                meta: {
                    requiresAuth: false,
                    id: "no-found"
                }
            },
            {
                path: "/error-page",
                name: "errorpage",
                component: PageNotFoundMain,
                meta: {
                    requiresAuth: false,
                    id: "error-page"
                },
            },
            {
                path: "/:pathMatch(.*)*",
                component: PageNotFoundMain,
                meta: {
                    requiresAuth: false,
                    id: "error-page"
                },
            },
            {
                path: "/privacy-policy",
                name: "privacy-policy",
                component: PrivacyPolicyMain,
                meta: {
                    requiresAuth: false,
                    id: "privacy-policy"
                }
            },
            {
                path: "/terms-of-use",
                name: "terms-of-use",
                component: TermOfUseMain,
                meta: {
                    requiresAuth: false,
                    id: "terms-of-use"
                }
            },
            //   {
            //     path: "/unauthorized",
            //     name: "unauthorized",
            //     component: UnauthorizedPage,
            //     meta: {
            //       requiresAuth: false,
            //       id: "unauthorized"
            //     }
            //   }
            {
                path: "/post-maintenance",
                name: "blog-post-maintenance",
                component: PostMaintenance,
                meta: {
                    requiresAuth: false,
                    id: "blog-post-maintenance"
                }
            },
            {
                path: "/post-list",
                name: "blog-post-list",
                component: PostList,
                meta: {
                    requiresAuth: false,
                    id: "blog-post-list"
                }
            },
            {
                path: "/login-form",
                name: "login-form",
                component: LoginForm,
                meta: {
                    requiresAuth: false,
                    id: "login-form"
                }
            }
        ]
    },
    {
        path: "/",
        component: SideMenu,
        children: [
            {
                path: "/",
                name: "side-menu-start",
                component: HomeMain,
                meta: {
                    requiresAuth: false,
                    id: "home"
                },
            },
            {
                path: "/about",
                name: "side-menu-about",
                component: AboutMain,
                meta: {
                    requiresAuth: false,
                    id: "about"
                }
            },
            {
                path: "/blog",
                name: "side-menu-blog",
                component: BlogMain,
                meta: {
                    requiresAuth: false,
                    id: "blog"
                }
            },
            {
                path: "/contact",
                name: "side-menu-contact",
                component: ContactsMain,
                meta: {
                    requiresAuth: false,
                    id: "contact"
                },
            },
            {
                path: "/home",
                name: "side-menu-home",
                component: HomeMain,
                meta: {
                    requiresAuth: false,
                    id: "home"
                },
            },
            {
                path: "/portfolio",
                name: "side-menu-portfolio",
                component: PortfolioMain,
                meta: {
                    requiresAuth: false,
                    id: "portfolio"
                },
            },
            {
                path: "/services",
                name: "side-menu-services",
                component: ServicesMain,
                meta: {
                    requiresAuth: false,
                    id: "services"
                },
            },
            {
                path: "/components",
                name: "side-menu-components",
                component: ComponentsMain,
                meta: {
                    requiresAuth: false,
                    id: "components"
                },
            },
            {
                path: "/templates",
                name: "side-menu-templates",
                component: TemplatesMain,
                meta: {
                    requiresAuth: false,
                    id: "templates"
                },
            },
            {
                path: "/service-single",
                name: "side-menu-services-single",
                component: ServicesSingleMain,
                meta: {
                    requiresAuth: false,
                    id: "services"
                }
            },
            {
                path: "/blog-single",
                name: "side-menu-blog-single",
                component: BlogSingleMain,
                meta: {
                    requiresAuth: false,
                    id: "services"
                }
            },
            {
                path: "/portfolio-single",
                name: "side-menu-portfolio-single",
                component: PortfolioSingleMain,
                meta: {
                    requiresAuth: false,
                    id: "portfolio"
                }
            }
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { left: 0, top: 0 };
    },
});

export default router;
